import { classList } from '~/utilities/css';

const sharedButtonStyles = 'uic-text-center uic-rounded-md uic-px-[12px] uic-py-8 uic-font-quicksand uic-font-semibold uic-flex uic-items-center';

const styles = {
	variantClasses: {
		primary: classList([
			'uic-bg-azure uic-text-white uic-rounded-md',
			sharedButtonStyles,
		]),
		secondary: classList([
			'uic-bg-wolf uic-text-raisin',
			sharedButtonStyles,
		]),
		tertiary: classList([
			'uic-bg-[#b2b4b4] uic-text-raisin',
			sharedButtonStyles,
		]),
		alert: classList([
			'uic-bg-watermelon uic-text-white',
			sharedButtonStyles,
		]),
		outlined: classList([
			'uic-bg-white uic-border-1 uic-border-mercury',
			sharedButtonStyles,
			'uic-py-[7px]',
		]),
		text: classList([
			'uic-inline-block uic-w-fit',
			'uic-text-azure uic-rounded-md',
		]),
		link: 'uic-text-azure uic-underline uic-inline-block uic-w-fit',
	},
	sizeClasses: {
		small: 'uic-px-2 uic-py-1 uic-text-xxs',
		medium: 'uic-px-10 uic-py-8 uic-text-xs',
		large: 'uic-px-10 uic-py-8 uic-text-sm',
	},
	stateClasses: {
		disabled: 'uic-opacity-50 uic-cursor-not-allowed',
		loading: 'uic-opacity-50 uic-cursor-wait',
	},
};

export { styles as default, styles };
