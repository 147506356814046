import { Button as ButtonPrimitive, PressEvent, ButtonProps } from 'react-aria-components';
import { ReactNode } from 'react';
import { classList } from '~/utilities/css';
import styles from './styles';

type ReactChildren = ReactNode | ReactNode[] | null;
type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'alert' | 'outlined' | 'text' | 'link';
type ButtonStates = 'disabled' |'loading' | '';
type ButtonSizes = 'small' | 'medium' | 'large';

type Props = {
	children: ReactChildren,
	variant?: ButtonVariants,
	state?: ButtonStates,
	size?: ButtonSizes,
	className?: string | ((values?: string) => string),
	onPressHandler?: (e: PressEvent) => void
} & ButtonProps;

function Button({
	children,
	className = '',
	variant = 'primary',
	state,
	size = 'small',
	onPressHandler = () => { },
	...delegatedProps
}: Props) {
	const isLoading = state === 'loading';
	const isDisabled = state === 'disabled' || isLoading;
	const buttonStyles = classList([
		`${styles.variantClasses[variant]}`,
		`${styles.sizeClasses[size]}`,
		`${state ? styles.stateClasses[state] : ''}`,
		`${isDisabled ? 'uic-opacity-50 uic-cursor-not-allowed' : ''}`,
		`${(variant === 'text') ? '' : 'hover:uic-shadow-md'}`,
	]);

	return (
		<ButtonPrimitive
			className={`${className} ${buttonStyles}${isDisabled ? ' uic-opacity-50' : ''}`}
			onPress={(e) => !isDisabled && onPressHandler(e)}
			isDisabled={isDisabled}
			aria-busy={isLoading}
			{...delegatedProps}
		>
			{children}
		</ButtonPrimitive>
	);
}

export { Button as default, Button };
